<template>
    <wit-modal
        v-model="show"
        @input="emitInput"
        title="Import JSON"
        variant="primary"
        disableDBlock
        size="md"
        custom-class="import-json-modal"
    >
        <wit-input-group v-model="inputJson" type="textarea" input-label="Paste JSON" input-col="12" min-rows="6" />
        <b-row>
            <b-col>
                <Feedback
                    :state="step.checked ? !$v.$invalid : null"
                    invalid="You need to fill in the input"
                ></Feedback>
                <b-btn variant="primary" @click="importJson" :disabled="step.checked && $v.$invalid">
                    <i class="icon-check"></i>
                    <span> Import</span>
                </b-btn>
            </b-col>
        </b-row>

        <div class="import-json-modal__or small-info-text">or</div>

        <wit-input-group
            v-model="inputJsonFile"
            type="file"
            input-label="Drop JSON"
            input-col="12"
            @input="onFileInput"
        />
    </wit-modal>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

import Feedback from '@/components/Feedback.vue'
import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'
import WitModal from '@/components/Modals/WitModal.vue'

export default {
    components: {
        Feedback,
        WitInputGroup,
        WitModal,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    mixins: [validationMixin],
    validations: {
        inputJson: {required},
    },
    data() {
        return {
            show: false,
            inputJson: '',
            inputJsonFile: null,
            step: {
                checked: false,
            },
        }
    },
    watch: {
        value() {
            this.show = this.value
        },
    },
    created() {
        this.show = this.value
    },
    methods: {
        emitInput() {
            this.$emit('input', this.show)
        },

        importJson() {
            this.step.checked = true

            if (!this.$v.$invalid) {
                this.$emit('import', this.inputJson)

                this.inputJson = ''
                this.show = false
                this.emitInput()
            }
        },

        async onFileInput(value) {
            if (value) {
                const file = await this.getFileContens(value)

                this.$emit('import', file)

                this.inputJsonFile = null
                this.show = false
                this.emitInput()
            }
        },

        getFileContens(value) {
            const reader = new FileReader()
            const file = new Promise(
                resolve =>
                    (reader.onloadend = () => {
                        return resolve(reader.result)
                    })
            )
            reader.readAsText(value)

            return file
        },
    },
}
</script>

<style lang="scss">
.import-json-modal {
    legend {
        font-weight: 600;
        font-size: 0.87rem;
    }

    .import-json-modal__or {
        margin: 1rem 0;
        text-align: center;
    }
}
</style>
